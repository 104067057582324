/**
 * Created by BFR on 16/06/2020.
 */
define('pimProductPricesTabView',[
  'module',
  'marionette',
  'jqgridView',
  'app',
  'jquery',
  'moment',
  'underscore',
  'backbone',
  'template!pimProductPricesTabTpl',
  'pimProductPriceScalesView',
  'pimProductImprintsView',
  'pimProductImprintsPricesView',
  'pimProductPriceCalculatorView',
  'pimProductCompetitorsView',
  'pimProductSimilarsView',
  'ordersDetailOrderProductStockView'
], function (
  module, Marionette,
  JqGridView,
  App,
  $,
  moment,
  _,
  Backbone,
  viewTpl,
  PimProductPriceScalesView,
  PimProductImprintsView,
  PimProductImprintsPricesView,
  PimProductPriceCalculatorView,
  ProductCompetitorsView,
  ProductSimilarsView,
  StockView
) {
  'use strict';

  var PimProductPricesView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'prices-factor',

    ui: {
      lblModDate: '.lblModDate',
      lblPzModDate: '.lblPzModDate',
      blockLoading: '.js-loading-block',
      inputProductStatus: '.input-product-status',
      inputProductComment: '.input-product-comment',
      cbxShowInWebshop: '.cbx-show-in-webshop',
      approveLoading: '.js-app-loading',
      stock: '.stock'
    },

    regions: {
      scalesRegion: '.scales-region',
      imprintsRegion: '.imprints-region',
      imprintsPricesRegion: '.imprints-prices-region',
      calculatorRegion: '.calculator-region',
      competitorsRegion: '.competitors-region',
      similarsRegion: '.similars-region',
      stockRegion: '.stock-region'
    },

    events: {
      'change .select-technic': 'onPrintTechnicChange',
      'click .btn-add-scale': 'onBtnAddScaleClick',
      'click .btn-save-product': 'onBtnSaveProductClick',
      'click .btn-switch-custom-pricing': 'onBtnSwitchCustomPriceClick',
      'click .btn-switch-automated-pricing': 'onBtnSwitchAutomatedPriceClick',
      'change .cbx-show-in-webshop': 'onShowInWebshopChange',
      'change .input-product-status': 'onStatusChange',
      'change .input-product-comment': 'onCommentChange',
      'click .btn-copy-to-scale': 'onBtnCopyToScaleClick',
      'click .btn-reset': 'onResetClick'
    },

    renderStock: function() {
      this.stockView = new StockView({
          secId:this.model.get('currentResProductId'),
          pim:true
      });
      this.getRegion('stockRegion').show(this.stockView);
    },

    onResetClick: function (event) {
      this.pimProductImprintsView.reset(event);
      this.pimProductImprintsPricesView.reset(event);
      //this.trigger('glossaries:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    onShowInWebshopChange: function (event) {
      var cbx = $(event.target);
      this.model.getCurrentResProduct().set('showInWebshop', cbx.is(':checked'));
    },

    onStatusChange: function (event) {
      var input = $(event.target);
      this.model.getCurrentResProduct().set('status', input.val());
    },

    onCommentChange: function (event) {
      var input = $(event.target);
      this.model.getCurrentResProduct().set('comment', input.val());
    },

    onBtnSwitchCustomPriceClick: function () {
      this.options.useFactorV2 = false;
      this.onBtnSaveProductClick();
    },

    onBtnSwitchAutomatedPriceClick: function () {
      this.options.useFactorV2 = false;
      this.pimProductPriceScalesView.collection.reset();
      this.onBtnSaveProductClick();
    },

    onBtnSaveProductClick: function () {

      this.ui.blockLoading.show();
      var cbx = this.$el.find(this.ui.cbxShowInWebshop);

      this.model.getCurrentResProduct().set('showInWebshop', cbx.is(':checked'));
      this.model.getCurrentResProduct().set('status', this.ui.inputProductStatus.val());
      this.model.getCurrentResProduct().set('comment', this.ui.inputProductComment.val());

      var factors = [];
      _.each(this.pimProductPriceScalesView.collection.models, function (price) {
        factors.push(price.get('factorModel'));
      });
      var p1 = this.model.getCurrentResProduct().save();
      if (this.options.useFactorV2) {
        $.when(p1).done(_.bind(function () {
          var p2 = this.model.getCurrentResProduct().saveImprint();
          $.when(p2).done(_.bind(function () {
            this.ui.blockLoading.hide();
            this.trigger('pim-product-prices:refresh');
          }, this));
        }, this));
      } else {
        $.when(p1).done(_.bind(function () {
          var p2 = this.model.getCurrentResProduct().saveImprint();
          var p3 = this.options.printZonePrices.save();
          var p4 = this.trigger('product-prices:update-prices', factors);
          $.when(p2, p3, p4).done(_.bind(function () {
            this.ui.blockLoading.hide();
            this.trigger('pim-product-prices:refresh');
          }, this));
          }, this));
      }
    },

    onPrintTechnicChange: function (event) {
      var cbxTechnic = this.$el.find(event.target);
      this.getPriceAndRenderScale(Number(cbxTechnic.val()) - 1);
    },

    onBtnAddScaleClick: function () {
      var newScale = _.clone(this.pimProductPriceCalculatorView.collection.models[
      this.pimProductPriceCalculatorView.collection.length - 1].toJSON());
      newScale.quantity = Number(newScale.quantity) * 2;
      newScale.factorModel = _.clone(newScale.factorModel);
      newScale.factorModel.quantity *= 2;
      delete newScale.factorModel.secId;
      this.pimProductPriceScalesView.collection.create(newScale);
    },

    onBtnCopyToScaleClick: function () {
      this.options.price.scale = this.pimProductPriceCalculatorView.collection.toJSON();
      this.renderScales(this.options.price);
    },

    getPriceAndRenderScale: function (index) {
      var that = this;
      return new Promise(function (resolve) {
        that.ui.blockLoading.show();
        var config = that.options.config;

        config.printZonesColors = {};
        if (that.printZones.length > index) {
          if (that.printZones[index].pz) {
            config.printZonesColors[that.printZones[index].pz.pimId] = 1;
          }
        }

        if (that.model.getCurrentResProduct() !== null) {
          var request = App.request('pim:products:get-price-for-configuration',
            that.model.getCurrentResProduct().get('id'), that.options.config, true);
          $.when(request).done(_.bind(function (price) {


            if (this.options.useFactorV2 && !price.customScales) {
              price.scale = _.filter(price.scale, _.bind(function (scale) {
                if (Number(scale.total) > this.options.factorsV2.param2 &&
                  (!this.options.factorsV2.param3 ||
                    this.options.factorsV2.param3 === 0 ||
                    Number(scale.total) < this.options.factorsV2.param3)) {
                  return true;
                }
                return false;
              }, this));
            }

            _.each(price.scale, _.bind(function (scale, index) {
              scale.factorModel = that.options.productPrices.findByQuantity(scale.quantity);
              if (scale.factorModel === null) {
                scale.factorModel = {
                  quantity: Number(scale.quantity),
                  factor: 1.3,
                  defaultFactor:
                    that.options.pricesFactorSup.length > index ? that.options.pricesFactorSup[index].factor : 1.3
                };
                scale.previousFactor = scale.factorModel.factor;
              } else {
                scale.factorModel = scale.factorModel.toJSON();
                scale.factorModel.defaultFactor =
                  that.options.pricesFactorSup.length > index ? that.options.pricesFactorSup[index].factor : 1.3;
                scale.previousFactor = scale.factorModel.factor;
              }
            }, that));
            that.renderScales(price);
            that.ui.blockLoading.hide();
            resolve();
          }, that));
        }
      });
    },

    renderScales: function (price) {
      this.customPrice = (_.filter(price.scale, function (ps) {
        return ps.factorModel.price > 0;
      }).length > 0) || (_.filter(price.scale, function (ps) {
        return ps.productUnitPrice > 0;
      }).length === 0);

      var colPrice = new Backbone.Collection(price.scale);
      colPrice.pimPrintFactors = this.options.pimPrintFactors;
      colPrice.pzs = this.printZones;
      colPrice.printZonePrices = this.options.printZonePrices;
      colPrice.useFactorV2 = this.options.useFactorV2;
      colPrice.factorsV2 = this.options.factorsV2;
      this.pimProductPriceScalesView = new PimProductPriceScalesView({
        collection: colPrice,
        customPrice: this.customPrice
      });
      this.getRegion('scalesRegion').show(this.pimProductPriceScalesView);

      this.pimProductPriceCalculatorView = new PimProductPriceCalculatorView({
        collection: new Backbone.Collection(price.scale),
        customPrice: this.customPrice
      });
      this.getRegion('calculatorRegion').show(this.pimProductPriceCalculatorView);
      this.generateOldestLabel(this.options.productPrices.toJSON());
      this.generatePzOldestLabel(this.options.printZonePrices.toJSON());
    },

    onRender: function () {
      this.printZones = this.buildPzs();
      var that = this;
      this.getPriceAndRenderScale(0).then(function () {

        var colPa = new Backbone.Collection(that.printZones);
        colPa.scales = that.options.price.scale;
        colPa.productShare = that.options.productShare;
        colPa.pimPrintFactors = that.options.pimPrintFactors;
        colPa.printZonePrices = that.options.printZonePrices;
        colPa.useFactorV2 = that.options.useFactorV2;

        that.pimProductImprintsView = new PimProductImprintsView({
          collection: colPa,
          customPrice: that.customPrice,
          resProduct: that.model.getCurrentResProduct()
        });
        that.pimProductImprintsPricesView = new PimProductImprintsPricesView({
          collection: colPa,
          customPrice: that.customPrice,
          resProduct: that.model.getCurrentResProduct()
        });
        that.getRegion('imprintsRegion').show(that.pimProductImprintsView);
        that.getRegion('imprintsPricesRegion').show(that.pimProductImprintsPricesView);
        that.competitorsView = new ProductCompetitorsView({
          collection: that.options.competitorsCollection,
          model: that.options.model
        });
        that.getRegion('competitorsRegion').show(that.competitorsView);

        that.similarsView = new ProductSimilarsView({
          collection: that.options.similarsCollection,
          model: that.options.model
        });
        that.getRegion('similarsRegion').show(that.similarsView);
      });
      this.renderStock();
    },

    buildPzs: function () {
      var that = this;
      var pzs = [], index = 1;

      _.each(this.model.get('data').get('printAreas').models, function (pa) {
        _.each(pa.get('printZones').models, function (pz) {
          pzs.push({
            pa: pa.toJSON(),
            pz: pz.toJSON(),
            product: that.model,
            resProduct: that.model.getCurrentResProduct(),
            index: index
          });
          index++;
        });
      });
      return pzs;
    },

    serializeData: function () {
      var templateData = {};
      templateData.product = this.model.getCurrentResProduct().toJSON();
      templateData.price = this.options.price;
      templateData.nbrTechnic = this.options.price.impt ? this.options.price.impt.length : 0;
      templateData.useFactorV2 = this.options.useFactorV2;
      return templateData;
    },

    generatePzOldestLabel: function (factors) {
      var modUser = null, oldest = null;
      if (factors) {
        // jshint ignore:start
        for (var p in factors) {

          _.each(factors[p], function (price) {
            if (price.modDate) {
              var currentDate = moment(price.modDate);
              if (oldest === null || currentDate.isAfter(oldest)) {
                oldest = currentDate;
                if (price.userMod) {
                  modUser = price.userMod.email;
                }
              }
            }
          });
        }
        // jshint ignore:end
      }
      if (oldest) {
        oldest = oldest.format('DD/MM/YYYY, HH:mm:ss');
        oldest = _.i18n('prices.modifiedOn') + ' ' + oldest;
        if (modUser) {
          oldest += ' ' + _.i18n('prices.by') + ' ' + modUser;
        }
      }
      this.ui.lblPzModDate.html(oldest);
    },

    generateOldestLabel: function (factors) {
      var modUser = null, oldest = null;
      if (factors) {
        _.each(factors, function (price) {
          if (price.modDate) {
            var currentDate = moment(price.modDate);
            if (oldest === null || currentDate.isAfter(oldest)) {
              oldest = currentDate;
              if (price.userMod) {
                modUser = price.userMod.email;
              }
            }
          }
        });
      }
      if (oldest) {
        oldest = oldest.format('DD/MM/YYYY, HH:mm:ss');
        oldest = _.i18n('prices.modifiedOn') + ' ' + oldest;
        if (modUser) {
          oldest += ' ' + _.i18n('prices.by') + ' ' + modUser;
        }
      }
      this.ui.lblModDate.html(oldest);
    }
  });

  module.exports = PimProductPricesView;
});

